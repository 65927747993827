import React, { useState, useRef, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { window, document } from "browser-monads"
import useBreakpoint from "~utils/useBreakpoint"
import useCheckoutSettings from "~utils/useCheckoutSettings"
import formatColors from "~utils/formatColors"
import { useCart, prepareProductForTracking, userProperties } from "~context/siteContext"
import toFixed from "~utils/toFixed"

import {
  Magnifier,
} from "react-image-magnifiers";

import Section from "~components/global/section"
import Stars from "~components/global/stars"

import RenderModule from "~components/renderModule"
import Slider from "~components/global/slider"
import ProductBadge from "~components/global/productBadge"
import ProductBadgeMini from "~components/global/productBadgeMini"
import { SelectButton, SubmitButton } from "~components/global/formElements"
import { X } from "~components/global/svg"
import Button from "~components/global/button"
import AddToCart from "~components/cart/addToCart"
import ProductDetail from "~components/modules/productDetail"
import Ticker from "~components/modules/ticker"
import IlloGrid from "~components/modules/illoGrid"
import TextImage from "~components/modules/textImage"
import GroupGrid, { GroupTile } from "~components/modules/groupGrid"

import Newsletter from '~components/global/newsletter'
import TextIllo from "~components/modules/textIllo"
import ProductImages from "~components/product/productImages"
import ProductOptions from "~components/product/productOptions"
import ProductMeta from "~components/product/productMeta"
import smoothscroll from 'smoothscroll-polyfill';
import resolveLink from "~utils/resolveLink"

import StructuredData from "~components/structuredData"
import { useLocation } from '@reach/router'
import { dlViewItem } from "../../utils/dlFunctions"

const ProductGroup = ({ productData, groupData }) => {

  const loc = useLocation()
  const { cartLoaded } = useCart()

  const [currentVariant, setCurrentVariant] = useState(false)
  const [firstImageLoaded, setFirstImageLoaded] = useState(false)
  const { addItem } = useCart()
  const { rechargeDiscount, cartTitle } = useCheckoutSettings()
  const [modules, setModules]  = useState([])

  const { mobile, tablet } = useBreakpoint()
  const addRef = useRef()
  const detailRef = useRef()
  const variants = productData.main.variants
  const stampedReviews = productData?.main?.modules?.filter(module => module._type === `productReviews`)[0]?.stampedReviews

  const sortedReviewsCopy = stampedReviews?.data?.slice()
  const sortedReviews = sortedReviewsCopy?.sort((a, b) => b.reviewRating - a.reviewRating)
  const highestReview = sortedReviews?.[0]

  const allCategories = groupData.categories.map(category => (category.products))
  const merged = [].concat.apply([], allCategories)
  const allReviews = merged.map(product => (JSON.parse(product.content?.shopify?.stampedData)?.total))
  const allStarReviews = merged.map(product => (JSON.parse(product.content?.shopify?.stampedData)?.rating))
  const reviewsSum = allReviews.reduce((a, b) => a + b, 0)
  const starsSum = allStarReviews.reduce((a, b) => a + b, 0)
  const starAvg = starsSum / allStarReviews.length

  // this function is to check basic product data for overrides at a variant level
  // there is probably a much neater way to write this
  const check = (key, empty) => {
    if(currentVariant
      && variantBasic
      && variantBasic[key]
      && (typeof variantBasic[key] !== 'object' || variantBasic[key].length > 0)){
      return variantBasic[key]
    }
    else if (productBasic && productBasic[key]) {
      return productBasic[key]
    }
    return empty
  }

  const productBasic = productData.main.basic
  const variantBasic = currentVariant ? currentVariant.content.main.basic : null

  const productColorData = {
    primaryColor: check('primaryColor'),
    secondaryColor: check('secondaryColor'),
    primaryColorOverride: check('primaryColorOverride'),
    secondaryColorOverride: check('secondaryColorOverride'),
    highlightColor: check('highlightColor'),
  }

  const productColors = formatColors(productColorData)
 
  const scrollToAdd = e => {
    e.preventDefault()
    let offset = 0 - (document.body.getBoundingClientRect().top - addRef.current.getBoundingClientRect().top)
    offset = offset - (mobile ? 150 : 250)
    window.scrollTo({top: offset, behavior: 'smooth'})
  }

  const scrollToDetail = e => {
    e.preventDefault()
    let offset = 0 - (document.body.getBoundingClientRect().top - detailRef.current.getBoundingClientRect().top)
    offset = offset - (tablet ? 50 : 80)
    window.scrollTo({top: offset, behavior: 'smooth'})
  }

  const addToCart = options => {
    const item = {
      _rawShopify: currentVariant.content._rawShopify,
      vId: currentVariant.content._rawShopify.variantId,
      title: groupData.title,
      subTitle: currentVariant.content.main.title,
      fluid: images.length > 0 ? images[0].asset.fluid : null,
      subscribe: options.frequency > 0,
      price: currentVariant.content._rawShopify.price,
      subPrice: toFixed((currentVariant.content._rawShopify.price * ((100 - rechargeDiscount) / 100)), 2),
      qty: options.qty,
      frequency: options.frequency,
      unit: 'week'
    }
    addItem(item, true)
    // It's in the cart 🎉
  }

  const handleVariantChange = e => {
    let newVariantId = e.value
    let newVariant = variants.find(v => v.content._rawShopify.variantId === newVariantId)
    if(newVariant){
      setCurrentVariant(newVariant)
    }
  }

  useEffect(() => {
    smoothscroll.polyfill()
    setCurrentVariant(variants[0])
  }, [])

  useEffect(() => {
    if(currentVariant && cartLoaded){
      dlViewItem({...productData}, groupData.catTitle, currentVariant)
    }
  }, [currentVariant, cartLoaded])

  // useEffect(() => {
  //   if(window?.ElevarDataLayer.push && currentVariant){
  //     console.log(document.referrer)
  //     window.ElevarDataLayer.push({
  //       "event": "dl_select_item",
  //       "user_properties": userProperties,
  //       "_elevar_internal": {
  //           "isElevarPush": true
  //       },
  //       "ecommerce": {
  //           "currencyCode": "AUD",
  //           "click": {
  //               "actionField": {
  //                   'list': "",
  //                   'action': 'click'
  //               }, // this should be the collection page URL
  //               "products": [prepareProductForTracking(currentVariant?.content)] // See the products array below
  //           }
  //       },
  //     });
  //   }
  //   console.log(loc)
  // }, [currentVariant])

  let images = check('productImages', [])

  const firstImageRatio = images[0].asset.fluid.aspectRatio

  // Modules Processing
  useEffect(()=> {
    if (modules.length) {
      const stampedData = productData?._rawShopify?.stampedData ? JSON.parse(productData._rawShopify.stampedData) : {}
      const reviewsModuleIndex = modules.findIndex(module => module._type === `productReviews`)

      if (reviewsModuleIndex > -1) {
        modules[reviewsModuleIndex] = {...modules[reviewsModuleIndex], stampedReviews: stampedData}
      }
    } 
  }, [modules])

  useEffect(()=> {
    setModules(productData?.main?.modules || [])  
  }, [])

  const numberWithCommas = (x) => {
    if(!x) return null
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return(
    <>
    <StructuredData 
      productData={productData}
      review={highestReview}
      reviews={stampedReviews}
      stampedData={productData?._rawShopify?.stampedData ? JSON.parse(productData._rawShopify.stampedData) : null}
      groupData={groupData}
    />
      <Section name="product-title">
        <div className="product-title__title">
          <h1 className="l-sans">{groupData.title}</h1>
          { reviewsSum > 0 &&
            <div className="product-title__review-summary">
              <Stars num={Math.round(starAvg)} /> <span className="s-caps">{numberWithCommas(reviewsSum)} reviews</span>
            </div>
          }
        </div>
      </Section>
      <Section name="product-info">
        <ProductImages title={check('packageDescription', '')}>
          {images.map((image, i) => (
            <div style={{
              height: firstImageLoaded ? 'auto' : '0px',
              paddingBottom: firstImageLoaded ? 0 : ( 1 / firstImageRatio) * 100 + '%',
            }}>
            <Magnifier
              imageSrc={image.asset?.small?.srcWebp}
              largeImageSrc={image.asset?.large?.srcWebp}
              dragToMove={tablet}
              onImageLoad={i === 0 ? () => setFirstImageLoaded(true) : null}
            />
            </div>
          ))}
        </ProductImages>
        <ProductOptions
          catTitle={groupData.catTitle}
          categories={groupData.categories}
          groupSlug={groupData.slug.current}
          currentProduct={productData}
        />
        <div className="product-meta">
          <ProductMeta title={productData.main.title} desc={check('tagline', null)} scrollToDetail={scrollToDetail}>
            <div className="product-meta__summary">
              <ul className="dashed">
                {check('description', []).map(point => (
                  <li>{point}</li>
                ))}
              </ul>
            </div>
          </ProductMeta>
          <div className="product-meta__add-to-cart" ref={addRef}>
            <AddToCart
            sub={true}
            types={variants.map(v => ({
              value: v.content._rawShopify.variantId,
              text: v.content._rawShopify.variantTitle
            }))}
            price={currentVariant && currentVariant.content._rawShopify.price}
            subDiscount={rechargeDiscount}
            showUnits={check('unitMessage', false)}
            unit={check('unitName', false)}
            multiplier={check('itemsPerUnit', false)}
            onVariantChange={handleVariantChange}
            onAddToCart={addToCart}/>
          </div>
        </div>
      </Section>
      <div className="product-flexible-content" ref={detailRef}>
        <Ticker text={check('tagline', '')} bg={productColors.safe[0]} textColor={productColors.safe[1]}>
          {check('tagline', '')}
        </Ticker>
        {modules && modules.map(m => (
          <RenderModule
            key={m._key}
            type={m._type}
            m={m}
            productColors={productColors}
            scrollToAdd={scrollToAdd}
            productTitle={productData.main.title}
          />
        ))}
      </div>
    </>
  )
}

export default ProductGroup

import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Select, RadioButton, Incrementer, SubmitButton } from "~components/global/formElements"
import { Loop, Tick } from "~components/global/svg"
import useCheckoutSettings from "~utils/useCheckoutSettings"
import axios from 'axios'
import { useDefaultOrder } from "~context/siteContext"
import toFixed from "~utils/toFixed"

const AddToCart = ({ sub, types, unit, multiplier, onAddToCart, onVariantChange, price, subDiscount, showUnits }) => {

  const { frequencyOptions, cartTitle } = useCheckoutSettings()
  const [qty, setQty] = useState(1)
  const [frequency, setFrequency] = useState(null)
  const [subSelected, setSubSelected] = useState(false)
  const [defaultOrder, setDefaultOrder] = useDefaultOrder()

  useLayoutEffect(() => {
    if(sub){
      // const shouldSelectSub = (window.history.state && (window.history.state.referrer === '/subscribe-save'))  || defaultOrder === 'sub'
      const shouldSelectSub = window.previousPath?.includes('subscribe-save')
      setFrequency(frequencyOptions[1].value)
      setSubSelected(shouldSelectSub)
    }
  }, [])

  // useEffect(() => {
  //    setDefaultOrder(subSelected ? 'sub' : 'once')
  // }, [subSelected])

  return(
    <div className="add-to-cart">
    {types && types.length > 1 &&
      <div className="add-to-cart__variant">
        <Select
        name="variant"
        label="Product Type"
        onChange={e => onVariantChange(e)}
        options={types}
        />
      </div>
      }
      {sub &&
        <div className="add-to-cart__sub">
          <RadioButton onClick={() => setSubSelected(!subSelected)} selected={subSelected} secondaryIcon={<span>${toFixed((price * ((100 - subDiscount) / 100)), 2)}</span>}>
            Subscribe & save <span>{subDiscount}%</span>
          </RadioButton>
          {subSelected &&
            <Select
            name="delivery-frequency"
            primaryIcon=<Loop />
            onChange={e => setFrequency(e.value)}
            options={frequencyOptions}
            defaultValue={frequency ?? null}
            >
            </Select>
          }
        </div>
      }
      <div className="add-to-cart__one-time">
        <RadioButton selected={!subSelected} onClick={() => setSubSelected(sub ? !subSelected : false)} secondaryIcon={<span>${price}</span>}>
          One-time purchase
        </RadioButton>
      </div>
      <div className="add-to-cart__finalise">
        <Incrementer
          multiplier={showUnits && multiplier}
          unitName={showUnits && unit}
          initialValue={1}
          onChange={v => setQty(v)}
        />
        <SubmitButton onClick={() => onAddToCart({
          frequency: subSelected ? frequency : null,
          qty: qty
        })}>
          Add to {cartTitle}
        </SubmitButton>
      </div>
    </div>
  )
}

export default AddToCart
